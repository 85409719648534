<template>
    <img v-on-screen.onceenter="{
        minShowHeight:100,
        isForceHeight:true,
        minShowWidth:100,
        callbk:itemInScreen
    }" :src="src" alt="" onerror="this.src='/assets/images/none.png'">
</template>

<script>
    export default {
        name: "LazyImage",
        props: {
            dataSrc: String
        },
        watch:{
            dataSrc(){
                if(this.showed){
                    this.src = this.dataSrc
                }
            }
        },
        data() {
            return {
                src: '/assets/images/none.png',
                showed: false
            }
        },
        methods: {
            itemInScreen() {
                this.src = this.dataSrc
                this.showed = true
            }
        }
    }
</script>

<style scoped>

</style>