<template>
    <div v-if="goods">
        <a-drawer
                :title="goods.sku_name"
                placement="right"
                :closable="true"
                :visible="active"
                @close=" active = false "
                width="496px"
                class="goods-image-drawer"
        >
            <div class="modal-content">
                <p v-if="!goods.white_image" class="mb-2 text-warning">
                    <span><b>警告</b> 该商品没有白底图</span>
                </p>
                <div v-if="loading" class="loading">
                    <a-spin :spinning="loading"></a-spin>
                </div>

                <div class="swiper-box">

                    <div class="thumb-img" v-if="main">
                        <span class="thumb-img-tag bg-primary tag-main">主图</span>

                        <div class="content-img-container">
                            <div class="content-img-box bg-gz">
                                <img :src="main" alt="">
                            </div>
                        </div>

                        <div class="water-fall-card-content">
                            <a class="text-warning" type="flat" @click="clothesSegment(main)">
                                服饰分割
                            </a>
                            <a class="text-warning" type="flat" @click="goodsSegment(main)">
                                商品分割
                            </a>
                        </div>
                    </div>

                    <div class="thumb-img" v-if="goods.white_image">
                        <span class="thumb-img-tag bg-success tag-white">白底图</span>

                        <div class="content-img-container">
                            <div class="content-img-box bg-gz">
                                <img :src="goods.white_image" alt="">
                            </div>
                        </div>


                        <div class="water-fall-card-content">
                            <a class="text-warning" type="flat" @click="clothesSegment(goods.white_image)">
                                服饰分割
                            </a>
                            <a class="text-warning" type="flat" @click="goodsSegment(goods.white_image)">
                                商品分割
                            </a>
                        </div>

                    </div>

                    <template v-if="images.length">
                        <template v-for="(image,ik) in images">
                            <div class="thumb-img" :key="ik">
                                <span class="thumb-img-tag bg-warning">轮播图</span>

                                <div class="content-img-container">
                                    <div class="content-img-box bg-gz">
                                        <img :src="image" alt="">
                                    </div>
                                </div>

                                <div class="water-fall-card-content">
                                    <a class="text-warning" type="flat" @click="clothesSegment(image)">
                                        服饰分割
                                    </a>
                                    <a class="text-warning" type="flat" @click="goodsSegment(image)">
                                        商品分割
                                    </a>
                                </div>

                            </div>
                        </template>
                    </template>
                </div>

            </div>
        </a-drawer>

        <a-modal v-model="showTplImageModal" @cancel="deleteTmp">
            <div class="tmp-image">
                <img v-if="tmpImage" :src="tmpImage" alt="">
            </div>
            <div slot="footer">
                <template>
                    <button class="btn" v-if="isThirdGoods" @click="setWhiteImageAndClose">使用该图搭配</button>
                </template>

                <button class="btn btn-white" @click="deleteTmp">取消</button>
            </div>
        </a-modal>
    </div>
</template>

<script>

    import api from "../../../repo/api";

    export default {
        name: "goods_image",
        computed: {},
        props: {
            initGoods: Object,
            initActive: Boolean,
            isThirdGoods: Boolean,
        },
        data() {
            return {
                main: "",
                goods: this.initGoods,
                active: this.initActive,
                showTplImageModal: false,
                images: [],
                tmpImage: null,
                loading: false,
            }
        },
        watch: {
            'goods'() {

                let images = [];

                images = images.concat(this.goods.images)

                this.images = [];

                images.map(el => {
                    if (this.images.indexOf(el) === -1) this.images.push(el)
                })

                this.main = this.goods.thumb

                this.tmpImage = null
            }
        },
        mounted() {

        },
        methods: {
            setWhiteImageAndClose() {

                this.setWhiteImage(this.tmpImage)

                this.showTplImageModal = false

                setTimeout(() => {

                    this.hidden();

                    this.$emit('whiteImageChangedAndClose', this.goods, this.tmpImage, this)

                }, 50)
            },
            setWhiteImage(image) {

                // this.goods.white_image = image

                this.$emit('whiteImageChanged', this.goods, image, this);
            },

            clothesSegment(image) {

                this.loading = true

                api.post('/ast-ec/goods-segment', {type: 'clothes', image}, (data) => {

                    this.loading = false

                    if (data.code === 0) {

                        this.tmpImage = data.data.url
                        this.showTplImageModal = true

                    } else {
                        this.$message.error(data.msg);
                    }
                });
            },
            goodsSegment(image) {

                this.loading = true

                api.post('/ast-ec/goods-segment', {type: 'goods', image}, (data) => {

                    this.loading = false

                    if (data.code === 0) {

                        this.tmpImage = data.data.url
                        this.showTplImageModal = true

                    } else {

                        this.$message.error(data.msg);

                    }
                });
            },
            deleteTmp() {

                if (!this.tmpImage) return;

                this.showTplImageModal = false;
                this.tmpImage = null;
            },
            openNotify: function (msg, color = 'danger') {
                if (color === 'danger') color = 'error';
                this.$message[color](msg);
            },
            setMain(image) {

                this.main = image;

                this.$emit('mainImageChanged', this.goods, image, this);
            },
            showDetail(goods) {
                this.goods = goods
                this.active = true
            },
            hidden() {
                this.active = false
            }
        }
    }
</script>

<style lang="less" scoped>
    img {
        /*max-width: 100%;*/
    }

    .thumb-img {
        line-height: 0;
        position: relative;
        width: 200px;
        height: 200px;
        border: 1px solid #dddddd;
        margin-bottom: 10px;
    }

    .swiper-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .content-img-container {
        position: relative;
        padding-bottom: 100%;
    }

    .content-img-container .content-img-box {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: white;
        display: flex;
        align-content: center;
        justify-content: space-around;
        overflow: hidden;
    }

    .content-img-container .content-img-box img {
        height: 100%;
    }


    .water-fall-card-content {
        background: rgba(51, 51, 51, 0.8);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        /*top:0;*/
        opacity: 0;
        transition: all .3s;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-around;
        height: 48px;
    }

    .water-fall-card-content a {
        cursor: pointer;
        width: 88px;
        height: 32px;
        background: #FFFFFF;
        display: block;
        line-height: 32px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
    }

    .thumb-img:hover .water-fall-card-content {
        opacity: 1;
    }

    .thumb-img-tag {
        position: absolute;
        top: 10px;
        left: -16px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        display: block;
        z-index: 9;
        width: 56px;
        height: 24px;
        background: #9EC545;;
        text-align: center;

        &.tag-main {
            background: #EBAB63;
        }

        &.tag-white {
            background: #63D4EB;
        }
    }

    .swiper-box img {
        border: 1px solid #ddd;
        cursor: pointer;
    }

    .clearfix {
        margin: 20px 0;
        border-bottom: 2px dashed #dddddd;
    }

    .tmp-image {
        img {
            width: 100%;
        }
    }

    .loading {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        background-color: rgba(255, 255, 255, .3);
    }

    .goods-image-drawer {
        /deep/ .ant-drawer-body {
            padding-left: 32px;
            padding-right: 32px;
            padding-top: 16px;
        }

        /deep/ .ant-drawer-header {

            border-bottom: 0;
        }
    }
</style>