<template>
    <div class="ll-card card-box" :class="{'horizontal':horizontal}">

        <div v-if="item.error" class="goods-error">
            <span @click="clearError">
                <img src="/assets/icons/danger-close.png" alt="">
            </span>
            {{ item.error }}
        </div>

        <template v-if="thumbType === 'multi-map' ">
            <div class="multi-container bg-gz" @click="showImageDetail(item)" v-on-screen.onceenter="{
        minShowHeight:100,
        isForceHeight:true,
        minShowWidth:100,
        callbk:itemInScreen
    }">
                <div class="multi-box">
                    <div v-if="item.thumb" class="multi-img">
                        <lazy-image :data-src="item.thumb" alt=""/>
                    </div>
                    <div v-if="item.white_image" class="multi-img">
                        <lazy-image :data-src="item.white_image" alt=""/>
                    </div>
                    <template v-for="(image,ik) in item.images">
                        <div :key="ik" class="multi-img">
                            <lazy-image :data-src="image" alt=""/>
                        </div>
                    </template>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="thumb">
                <div class="content-img-container" slot="no-body">

                    <div class="content-img-box bg-gz" @click="showImageDetail(item)">

                        <template v-if=" thumbType === 'thumb' && item.thumb ">
                            <lazy-image
                                    :data-src=" item.thumb "
                                    alt="content-img"
                                    class="responsive card-img-top cursor-pointer"
                            />
                        </template>

                        <template v-else-if=" thumbType === 'white_image' && item.white_image ">
                            <lazy-image
                                    :data-src=" item.white_image "
                                    alt="content-img"
                                    class="responsive card-img-top cursor-pointer"
                            />
                        </template>
                        <template v-else>
                            <img
                                    src="/assets/images/none.png"
                                    alt="content-img"
                                    class="responsive card-img-top cursor-pointer"
                            />
                        </template>

                    </div>

                    <div class="goods-tps">
                        <div v-if="!item.white_image">该商品无白底图</div>
                    </div>

                    <div v-if="(+item.status) === 2" class="goods-fail" @click.stop>
                        <p>商品已失效</p>
                    </div>

                </div>
            </div>
        </template>

        <div v-if="mode !== 'thumb' " class="goods-detail">
            <div class="mb-1 shop-sale">
                <span class="text-muted" :title="item.shop_name">{{ item.shop_name.substr(0,5) }}</span>
                <span class="sale-num">
                    <span class="sale-title">销量/评论</span>
                    <span>{{ item.sale_num }}</span>
                </span>
            </div>

            <div class="title" :class="{'cursor-pointer':item.material_url}" :title="item.sku_name"
                 @click="openUrl(item.material_url)">
                <div class="goods-plate">
                    <span v-if="item.from === 'jd' " class="iconfont pc-jingdong"></span>
                    <span v-else-if="item.from === 'tb' " class="iconfont pc-taobao"></span>
                    <span v-else-if="item.from === 'vip' " class="iconfont pc-weipinhui"></span>
                </div>
                {{ item.sku_name }}
            </div>

            <div class="mt-5">
                <div class="details">
                    <span title="价格" class="prefix">
                        <span class="price">
                            ¥ {{ item.lowest_price }}
                        </span>

                    </span>
                    <span title="佣金">

                        <span class="comm">¥{{ item.commission}}</span>
                        <small class="text-minor">[{{ item.commission_rate }}%]</small>

                    </span>
                </div>

            </div>
        </div>

        <template v-if="editable">

            <goods-image @whiteImageChanged="whiteImageChanged"
                         @mainImageChanged="mainImageChanged"
                         @whiteImageChangedAndClose="whiteImageChangedAndClose"
                         :is-third-goods="isThirdGoods"
                         ref="images_detail"></goods-image>

        </template>
    </div>
</template>

<script>

    import GoodsImage from "./GoodsImage";
    import LazyImage from "@/components/LazyImage";
    import api from "../../../repo/api";

    export default {
        name: "GoodsCard",
        components: {LazyImage, GoodsImage},
        props: {
            item: Object,
            horizontal: Boolean,
            mode: String,
            isThirdGoods: Boolean,
            thumbType: {
                type: String,
                default: 'thumb'
            },
            editable: Boolean,
            autoLoadImage: Boolean
        },
        data() {
            return {
                inScreen: false,
            }
        },
        watch: {
            mode() {
                if (this.mode === 'multi-img' && this.autoLoadImage && this.inScreen) {
                    this.loadBanners()
                }
            }
        },
        methods: {
            openUrl(url) {
                if (!url) return
                window.open(url)
            },
            itemInScreen() {
                this.inScreen = true
                this.loadBanners()
            },
            loadBanners() {

                if (!this.autoLoadImage) return

                if (!this.editable) return

                if (this.item.images.length > 0) return


                api.get('/ast-ec/banners', {
                    plate: this.item.from,
                    sku_id: this.item.sku_id
                }, (data) => {

                    if (this.item.images.length > 0) return

                    if (data.code === 0) {
                        this.item.images = data.list

                        this.$forceUpdate()
                    }
                })

            },
            clearError() {
                delete this.item.error
                this.$forceUpdate()
            },
            showImageDetail(item) {
                if (!this.editable) return

                if (item.images.length === 0) {

                    api.get('/ast-ec/banners', {
                        plate: item.from,
                        sku_id: item.sku_id
                    }, (data) => {

                        if (data.code === 0) {
                            item.images = data.data
                            let ref = this.$refs['images_detail'];
                            ref.showDetail(item)
                        } else {
                            this.openNotify('获取图片失败！')
                        }
                    })

                } else {
                    let ref = this.$refs['images_detail'];
                    ref.showDetail(item)
                }
            },
            whiteImageChanged(goods, image, el) {
                this.$emit('whiteImageChanged', goods, image, el)
            },
            whiteImageChangedAndClose(goods, image, el) {
                this.$emit('whiteImageChangedAndClose', goods, image, el)
            },
            mainImageChanged(goods, image, el) {
                this.$emit('mainImageChanged', goods, image, el)
            },
            openNotify: function (msg, color = 'danger') {
                if (color === 'danger') color = 'error';
                this.$message[color](msg);
            },
        }
    }
</script>

<style lang="less" scoped>

    .prefix {
        font-size: 13px;
    }

    .brand {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .card-box {
        overflow: hidden;
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;

        &.horizontal {
            flex-direction: row;

            .content-img-container {
                width: 140px;
                overflow: hidden;
            }

            .goods-detail {
                flex: 1;
            }
        }
    }

    .red {
        color: #ff0000;
    }

    .details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 12px;
        line-height: 12px;
    }

    .ll-card {
        border-radius: 5px;
    }

    .goods-detail {
        margin-top: 8px;
    }

    .title {
        height: 45px;
        padding-bottom: 0;
        margin-bottom: 8px;
        margin-top: 8px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 24px;
    }

    .text-muted {
        color: #bbb;
        font-size: 12px;
    }

    .coupons-title {
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .price {
        font-size: 12px;
    }

    .origin-price {
        color: rgba(0, 0, 0, .45);
        font-size: 12px;
        text-decoration: line-through;
    }

    .coupon-link:hover {
        text-decoration: underline;
        text-decoration-color: #ff0000;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .content-img-container {
        position: relative;
        padding-bottom: 100%;
    }

    .content-img-container .content-img-box {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: white;
        display: flex;
        align-content: center;
        justify-content: space-around;
    }

    .content-img-container .content-img-box img {
        height: 100%;
        width: auto;
    }

    .plate-icon {
        position: absolute;
        top: -1px;
        left: -1px;
        width: 30px
    }

    .goods-tps {
        position: absolute;
        top: 0;
        right: 0;
        color: orangered;

        div {
            padding: 5px 10px;
            margin-bottom: 5px;
            color: orangered;
        }
    }

    .goods-plate {
        width: 16px;
        height: 16px;
        line-height: 16px;
        background-color: #dddddd;
        margin-right: 4px;
        display: inline-block;
    }

    .goods-fail {
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 2.2rem;
    }

    .multi-container {
        width: 100%;
        position: relative;
        padding-bottom: 100%;
        cursor: pointer;
    }

    .multi-box {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        align-content: flex-start;
    }

    .multi-img {
        width: 33.33%;
        height: 33.33%;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .comm {
        font-size: 12px;
        color: #FFAD00;
    }

    .shop-sale {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 17px;

        span {
            color: #999999;
        }

        .sale-title {
            margin-right: 8px;
        }
    }

    .goods-error {
        position: absolute;
        width: 390px;
        height: 40px;
        line-height: 40px;
        background: #FAF1EC;
        z-index: 5;
        left: 50%;
        margin-left: -195px;
        top: 52px;
        font-size: 14px;
        font-weight: 400;
        color: #F56C6C;
        padding-left: 32px;

        span {
            cursor: pointer;
            width: 16px;
            height: 16px;
            display: inline-block;

            img {
                width: 100%;
            }

            margin-right: 8px;
        }
    }

</style>